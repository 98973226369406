<template>
  <div
    :class="{
      wrapper: device,
      wrappermobile: !device,
    }"
    style='width:100%;'

  >
    <!-- <navbar allDrop="false" /> -->
    <div
      class="container"
      v-bind:style="!device ? 'margin-top: 100pt;' : 'margin-top: 0pt;'"
    >
      <div v-if="tipo === ''">
        <div class="headerPrinc" v-if="!device">
          <h1 class="title" style="font-weight: bold; font-size: 43pt">
            {{ $t("forms.title") }}
          </h1>
        <p style="text-align:justify;">
          {{ $t("forms.titlef1") }}
          <br><br>
          {{ $t("forms.titlef2") }}
          <br><br>
          {{ $t("forms.titlef3") }}
          <br><br>
          {{ $t("forms.titlef4") }}
          <br><br>
          {{ $t("forms.titlef5") }}
          <br><br>
          {{ $t("forms.titlef6") }}
          <br><br>
          {{ $t("forms.titlef7") }} <a href=https://denuncias.servicios-empresa.com/informacion.asp target=_blank>info</a>
        </p>

        </div>
        <div class="headerPrinc-mobile" v-else>
          <h1 class="title" style="font-weight: bold; font-size: 43pt" >
            {{ $t("forms.title") }}
          </h1>
        </div>
        <div class="header" v-if="!device">
          <button
            class="btn-light button"
            style="background-color: #666666; margin-right: 10pt"
            @click="changeType('consultation')"
            :style="{ backgroundColor: colorCompany }"
          >
            
            {{ $t("forms.consult") }}
          </button>
          <button
            class="btn-dark button" 
            style="background-color: black; margin-left: 10pt"
            @click="changeType('complaint')"
          >
            
            {{ $t("forms.complaint") }}
          </button>
          <br /><br /><br /><br /><br /><br /><br /><br />
          <font>{{ $t("forms.subtitle2") }}</font>
        </div>
        <div class="header" v-else>
          <div class="col">
            <div class="row" style="padding: 10pt; margin: 20pt">
              <button
                class="btn-light button"
                style="
                  background-color: #666666;
                  margin-right: 10pt;
                  font-size: 12pt;
                "
                @click="changeType('consultation')"
                :style="{ backgroundColor: colorCompany }"
              >
                <img src="../assets/img/tipo_1.png" style="width: 30pt" />
                {{ $t("forms.consult") }}
              </button>
            </div>
            <div class="row" style="padding: 10pt; margin: 20pt">
              <button
                class="btn-dark button"
                style="background-color: black; font-size: 12pt"
                @click="changeType('complaint')"
              >
                <img src="../assets/img/tipo_2.png" style="width: 30pt" />
                {{ $t("forms.complaint") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card header2"
        id="cardForm"
        :style="styleObject"
        v-if="tipo === 'consultation'"
      >
        <div class="row form">
          <div class="col">
            <input
              v-model.trim="denuncia.denu_nombre"
              type="text"
              class="form-control"
              id="nombre"
              :placeholder="$t(`forms.consultation.name`) + ' *'"
              maxlength="50"
              style="text-transform: uppercase"
            />
          </div>
          <div class="col">
            <input
              v-model.trim="denuncia.denu_apellidos"
              type="text"
              class="form-control"
              id="apellido"
              :placeholder="$t(`forms.consultation.surname`) + ' *'"
              maxlength="255"
              style="text-transform: uppercase"
            />
            {{$t('forms.consultation.otromedia')}}
          </div>
          <div class="col">
            <input
              v-model.trim="denuncia.denu_telefono"
              type="phone"
              class="form-control"
              id="telefono"
              :placeholder="$t(`forms.consultation.phone`)"
              maxlength="20"
              style="text-transform: uppercase"
            />
          </div>          
        </div>
        <div class="row form">
          <div class="col">
              <input
                v-model.trim="denuncia.denu_email"
                type="text"
                class="form-control inputForm"
                id="email"
                placeholder="*EMAIL"
                style="text-transform: uppercase"
              />
          </div>
          <div class="col">
              
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="denuncia.denu_tipo_dir"
              >
                <option
                  value="ordinario"
                  key="ordinario"
                >
                  {{$t(`forms.consultation.ordinario`)}}
                </option>
                <option
                  value="otros"
                  key="otros"
                >
                  {{$t(`forms.consultation.otros`)}}
                </option>
              </select>
          </div>
          <div class="col">
              <input
                v-model.trim="denuncia.denu_email2"
                type="text"
                class="form-control inputForm"
                id="email2"
                style="text-transform: uppercase"
                :placeholder="$t(`forms.consultation.mediocom`)"
              />
          </div>
        </div>
        <div class="row form">
          <div class="col">
            <input
              v-model.trim="denuncia.denu_organizacion"
              type="text"
              class="form-control"
              id="organizacion"
              :placeholder="$t(`forms.consultation.organization`)"
              maxlength="255"
              style="text-transform: uppercase"
            />
            <select
              class="form-select inputForm"
              aria-label="Elija una opción"
              v-model.trim="denuncia.denu_relacion_cd"
            >
              <option disabled selected value="0">
                {{ $t(`forms.consultation.relationship`) + " *" }}
              </option>
              <option
                v-for="rela of relations"
                :key="rela.rela_id"
                :value="rela.rela_id"
              >
                {{ rela.rela_nombre }}
              </option>
            </select>

            <select
              class="form-select inputForm"
              aria-label="Elija una opción"
              v-model.trim="denuncia.denu_naturaleza_cd"
            >
              <option disabled selected value="0">
                {{ $t(`forms.consultation.nature`) }}
              </option>
              <option
                v-for="natu of natures"
                :key="natu.natu_id"
                :value="natu.natu_id"
              >
                {{ natu.natu_nombre }}
              </option>
            </select>
          </div>
          <div class="col">
            <textarea
              v-model.trim="denuncia.denu_consulta"
              class="form-control"
              :placeholder="$t(`forms.consultation.message`) + ' *'"
              rows="9"
              style="text-transform: uppercase"
            />
          </div>
          <div class="row form">
            <FilePond
              ref="pondconsul"
              :label-idle="imgText"
              allow-multiple="true"
              max-files="10"
            />
          </div>
          <div class="form-check">
            <label
              class="form-check-label"
              for="flexCheckDefault"
              style="float: right"
            >
              {{ $t("forms.privacity") }}
              <a :href="rute" target="_blank">{{ $t("forms.policy") }}</a>
            </label>
            <input
              class="form-check-input"
              type="checkbox"
              v-model="privacitypolicy"
              id="flexCheckDefault"
              style="height: 10pt; float: right; margin-right: 6pt"
            />
              <label
                class="form-check-label"
                style="float: left"
              >
                {{ $t("forms.capa1_GEN") }}
                {{emp_nombre}} {{emp_cif}}
                {{ $t("forms.capa2_GEN") }}
                <a :href="rute" target="_blank">{{ $t("forms.policy") }}</a>
              </label>              
          </div>


        </div>
        <span
          v-if="formError"
          class="text-danger"
          style="float: right; font-size: 12px"
          >{{ $t("errors.fieldOb") }}</span
        >
        <br />
        <button
          id="btnSend"
          @click="enviarDenuncia()"
          type="button"
          class="btn-light button"
          style="background-color: #666666"
          :style="{ backgroundColor: colorCompany }"
        >
          <i class="fas fa-paper-plane"></i> {{ $t("actions.send") }}
        </button>
        <button
          id="btnClean"
          @click="cleanForm()"
          type="button"
          class="btn-light button"
        >
          <i class="fas fa-hand-sparkles"></i> {{ $t("actions.clean") }}
        </button>
      </div>
      <div v-else-if="tipo === 'complaint'">
        <ul class="nav nav-tabs" style="margin-top: 20pt" v-if="!device">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: !anonymous }"
              aria-current="page"
              href="#"
              @click="anonymous = false"
              >{{ $t("forms.nominal") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              style="cursor: pointer"
              class="nav-link"
              :class="{ active: anonymous }"
              @click="anonymous = true"
              >{{ $t("forms.anonymous") }}</a
            >
          </li>
        </ul>
        <ul
          class="nav nav-tabs"
          style="margin-top: 20pt; font-size: 12pt"
          v-else
        >
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: !anonymous }"
              aria-current="page"
              href="#"
              @click="anonymous = false"
              >{{ $t("forms.nominal") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              style="cursor: pointer"
              class="nav-link"
              :class="{ active: anonymous }"
              @click="anonymous = true"
              >{{ $t("forms.anonymous") }}</a
            >
          </li>
        </ul>
        <div class="card" id="cardForm2">
          <div v-if="!anonymous">
            <div class="row form">
              <div class="col">
                <input
                  v-model.trim="denuncia.denu_nombre"
                  type="text"
                  class="form-control inputForm"
                  id="nombre"
                  :placeholder="$t(`forms.consultation.name`) + ' *'"
                  style="text-transform: uppercase"
               />
              </div>
              <div class="col">
                <input
                  v-model.trim="denuncia.denu_apellidos"
                  type="text"
                  class="form-control inputForm"
                  id="apellido"
                  :placeholder="$t(`forms.consultation.surname`) + ' *'"
                  style="text-transform: uppercase"
                />
                {{$t('forms.consultation.otromedia')}}
              </div>
              <div class="col">
                <input
                  v-model.trim="denuncia.denu_telefono"
                  type="phone"
                  class="form-control inputForm"
                  id="telefono"
                  :placeholder="$t(`forms.consultation.phone`)"
                  style="text-transform: uppercase"
                />
              </div>
            </div>
            <div class="row form">
              <div class="col">
                <input
                  v-model.trim="denuncia.denu_email"
                  type="text"
                  class="form-control inputForm"
                  id="email"
                  placeholder="*EMAIL"
                  style="text-transform: uppercase"
                />
              </div>
              <div class="col">
                
                <select
                  class="form-select"
                  aria-label="Elija una opción"
                  v-model.trim="denuncia.denu_tipo_dir"
                >
                  <option
                    value="ordinario"
                    key="ordinario"
                  >
                    {{$t(`forms.consultation.ordinario`)}}
                  </option>
                  <option
                    value="otros"
                    key="otros"
                  >
                    {{$t(`forms.consultation.otros`)}}
                  </option>
                </select>
              </div>
              <div class="col">
                <input
                  v-model.trim="denuncia.denu_email2"
                  type="text"
                  class="form-control inputForm"
                  id="email2"
                  style="text-transform: uppercase"
                  :placeholder="$t(`forms.consultation.mediocom`)"
                />
              </div>
            </div>    
          </div>
          <div class="row form">
            <div class="col">
              <input
                v-if="!anonymous"
                v-model.trim="denuncia.denu_organizacion"
                type="text"
                class="form-control inputForm"
                id="organizacion"
                :placeholder="$t(`forms.consultation.organization`)"
                maxlength="255"
                style="text-transform: uppercase"
              />
              <select
                class="form-select inputForm"
                aria-label="Elija una opción"
                v-model.trim="denuncia.denu_relacion_cd"
              >
                <option disabled selected value="0">
                  {{ $t(`forms.consultation.relationship`) + " *" }}
                </option>
                <option
                  v-for="rel of relations"
                  :key="rel.rela_id"
                  :value="rel.rela_id"
                >
                  {{ rel.rela_nombre }}
                </option>
              </select>

              <select
                class="form-select inputForm"
                aria-label="Elija una opción"
                v-model.trim="denuncia.denu_naturaleza_cd"
              >
                <option disabled selected value="0">
                  {{ $t(`forms.comp.nature`) }}
                </option>
                <option
                  v-for="natu of natures"
                  :key="natu.natu_id"
                  :value="natu.natu_id"
                >
                  {{ natu.natu_nombre }}
                </option>
              </select>
              <input
                v-model.trim="denuncia.denu_personas"
                type="text"
                class="form-control inputForm"
                id="personasimplicadas"
                :placeholder="$t(`forms.comp.involved`) + ' *'"
                maxlength="255"
                style="text-transform: uppercase"
              />
              
            </div>
            <div class="col">
              <textarea
                v-model.trim="denuncia.denu_consulta"
                class="form-control"
                :placeholder="$t(`forms.comp.otherComplaints`) + ' *'"
                :rows="!anonymous ? '13' : '9'"
                style="text-transform: uppercase"
              />
            </div>
          </div>
          <div class="row form">
            <div class="col">
              <input
                v-model.trim="denuncia.denu_cuando"
                type="text"
                class="form-control inputForm"
                :placeholder="$t(`forms.comp.when`)"
                maxlength="255"
                style="text-transform: uppercase"
              />
            </div>
            <div class="col">
              <label
                ><br />{{ $t(`forms.comp.reiterativo`) }} &nbsp;&nbsp;</label
              >
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="NO"
                  v-model.trim="denuncia.denu_reiterativo"
                  style="padding: 5pt; height: 10pt"
                />
                <label class="form-check-label" for="inlineRadio1">{{
                  $t(`forms.comp.no`)
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="YES"
                  v-model.trim="denuncia.denu_reiterativo"
                  style="padding: 5pt; height: 10pt"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  $t(`forms.comp.yes`)
                }}</label>
              </div>
            </div>
          </div>
          <div class="row form" v-if="anonymous">
              <label
                class="form-check-label"
                style="float: left"
              >
                {{ $t("forms.anonimato") }}
              </label>
          </div>
          <div class="row form">
            <FilePond
              ref="pond"
              :label-idle="imgText"
              allow-multiple="true"
              max-files="10"
            />
            <div class="form-check">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="float: right"
              >
                {{ $t("forms.privacity") }}
                <a :href="rute" target="_blank">{{ $t("forms.policy") }}</a>
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="privacitypolicy"
                id="flexCheckDefault"
                style="height: 10pt; float: right; margin-right: 6pt"
              />
            </div>

          </div>
          <span
            v-if="formError"
            class="text-danger"
            style="float: right; font-size: 12px"
            >{{ $t("errors.fieldOb") }}</span
          >
          <br />
          <button
            id="btnSend"
            @click="enviarDenuncia()"
            type="button"
            class="btn-light button"
            style="background-color: #666666"
            :style="{ backgroundColor: colorCompany }"
          >
            <i class="fas fa-paper-plane"></i> {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            @click="cleanForm()"
            type="button"
            class="btn-light button"
          >
            <i class="fas fa-hand-sparkles"></i> {{ $t("actions.clean") }}
          </button>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div class="footer-legal" style="bottom:20px;">
        <p style="line-height: 37pt; font-size: 8pt" class="a-legal">
          <a class="a-legal" :href="hrefLegal"  target="_blank">{{
            $t("legal.legalWarn")
          }}</a>
          -
          <a class="a-legal" :href="hrefCookies" target="_blank">{{
            $t("legal.pcookies")
          }}</a>
          -
          <a class="a-legal" :href="hrefPrivacy" target="_blank">{{
            $t("legal.pprivacity")
          }}</a>
          -
          Developed by <a class="a-legal" href="https://www.consultoriacomunica.com.com" target="_blank">Consultoría Comunica y Desarrollo S.L.</a>          
        </p>
      </div>
    </div>
  </div>
  <footer-cookies />

  <!-- Modal -->
  <div
    class="modal fade"
    id="resultadoModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.acuse") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cerrar()"
            v-if="result == '0'"
          ></button>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-else
          ></button>
        </div>
        <div class="modal-body" v-if="result === '0' && !anonymous">
          {{ $t("forms.finalMesssage") }}
          <a style="color: #0d6efd" :href="link">{{ link }}</a>
          <br />
          {{ $t("forms.finalMesssage2") }}
          <span style="font-weight: bold">{{ cod_denu }}</span><br /><br />
          <br />{{ $t("forms.finalMessage3") }}
          <span style="font-weight: bold"><a :href="link2" target="_blank">CLICK</a></span>
        </div>
        <div class="modal-body" v-if="result === '0' && anonymous">
          {{ $t("forms.finalDenuncia") }}
          <br />
          <a style="color: #0d6efd" :href="link">{{ link }}</a>
          <br />
          <span style="font-weight: bold">{{ cod_denu }}</span>
          <br />
          {{ $t("forms.finalDenuncia2") }}<br /><br />       
          <br />{{ $t("forms.finalMessage3") }}
          <span style="font-weight: bold"><a :href="link2" target="_blank">CLICK</a></span>
        </div>
        <div class="modal-body" v-if="result !== '0'">
          {{ $t("forms.finalMesssage") }}
          ERROR!
        </div>
        <div class="modal-footer">
          <button
            @click="cerrar()"
            type="button"
            class="btn buttonClose"
            data-bs-dismiss="modal"
            v-if="result === '0'"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            type="button"
            class="btn buttonClose"
            data-bs-dismiss="modal"
            v-else
          >
            {{ $t("actions.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
import Navbar from "../components/Navbar.vue";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FooterCookies from "../components/FooterCookies.vue";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  name: "Home",
  components: {
    Navbar,
    FilePond,
    FooterCookies,
  },
  data() {
    return {
      tipo: "",
      denuncia: {
        denu_tipo_denuncia: "anonymous",
        denu_nombre: "",
        denu_apellidos: "",
        denu_telefono: "",
        denu_email: "",
        denu_email2: "",
        denu_organizacion: "",
        denu_relacion_cd: 0,
        denu_consulta: "",
        denu_tipo_delito: "",
        denu_personas: "",
        denu_departamento: 0,
        denu_sede: 0,
        denu_fuente: "",
        denu_empresa_cd: "",
        denu_naturaleza_cd: 0,
        link: "",
        link2: "",
        denu_reiterativo: 0,
        denu_cuando: "",
        denu_tipo_dir: "",
        denu_miembro: ""
      },
      departamentos: [],
      sedes: [],
      relations: [],
      natures: [],
      anonymous: true,
      imgText: this.$t("forms.imgText"),
      emp_id: this.$route.params.ip,
      emp_canal: this.$route.params.canal,
      result: "",
      cod_denu: "",
      formError: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      device: false,
      privacitypolicy: false,
      rute: "",
      hrefPrivacy: "",
      hrefLegal: "",
      hrefCookies: "",
      emp_nombre: "",
      emp_cif:""      
    };
  },
  created() {
    this.hrefPrivacy = `/politica-privacidad.asp?id=${this.emp_id}`;
    this.hrefLegal = `/aviso-legal.asp?id=${this.emp_id}`;
    this.hrefCookies = `/politica-cookies.asp?id=${this.emp_id}`;
    this.rute = `../../politica-privacidad.asp?id=${this.emp_id}`;
    this.getColorType();
    this.littleDrop();
    this.changeBreadCrumb(0);
    this.verImagen(this.emp_id);
    this.getDepartamentos();
    this.getSedes();
    this.getRelations();
    this.getNaturas();
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      this.device = true;
    } else {
      // false for not mobile device
      this.device = false;
    }
  },
  computed: {
    ...mapState(["colorCompany", "servidor"]),
    styleObject() {
      if (this.device) {
        return {
          "--margin-top": "20pt",
        };
      } else {
        return {
          "--margin-top": "120pt",
        };
      }
    },
    styleHeader() {
      if (this.device) {
        return {
          "--display-header": "block",
        };
      } else {
        return {
          "--display-header": "block",
        };
      }
    },
  },
  methods: {
    ...mapMutations([
      "changeColorCompanyGreen",
      "changeColorCompanyBlue",
      "changeColorCompanyRed",
      "changeColorCompanyOrange",
      "changeColorCompany4",
      "changeColorCompany5",
      "changeColorCompany6",
      "changeColorCompany7",
      "changeColorCompany8",
      "changeColorCompany9",
      "changeColorCompany10",
      "changeColorCompany11",
      "changeColorCompany12",
      "changeColorCompany13",
      "changeColorCompany14",
      "changeColorCompany15",
      "changeColorCompany16",
    ]),
    async getDepartamentos() {
      try {
        const empresa = {
          emp_id: this.emp_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=departamentos_denu`,
          empresa
        );
        if (res.data) {
          this.departamentos = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getSedes() {
      try {
        const empresa = {
          emp_id: this.emp_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=sedes_denu`,
          empresa
        );
        if (res.data) {
          this.sedes = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getRelations() {
      try {
        const empresa = {
          emp_id: this.emp_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=rela&ac=listado2&tipo=1`,
          empresa
        );

        if (res.data) {
          this.relations = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getNaturas() {
      try {
        const empresa = {
          emp_id: this.emp_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=natura&ac=listado2`,
          empresa
        );
        if (res.data) {
          this.natures = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async enviarDenuncia() {
      if (this.validateForm()) {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=grabar`,
          this.formatDatas()
        );
        if (res.data) {
          this.result = res.data.RESULT;
          this.cod_denu = res.data.DENUNCIA;
          this.link = `${this.servidor}${this.emp_id}/whistleblower/search`;
          this.link2 = `${this.servidor}api/export_denu.asp?id=${this.cod_denu}`;
          $("#resultadoModal").modal("show");
        }
      }
    },
    cerrar() {
      this.cleanForm();
      this.tipo = "";
    },
    consultaTodas() {
      this.$router.push("about");
    },
    cleanForm() {
      if (this.tipo === "complaint") {
        if (this.$refs.pond) {
          const lenghtPond = this.$refs.pond.getFiles().length;
          for (let i = 0; i < lenghtPond; i++) {
            this.$refs.pond.removeFile(i);
          }
        }
      } else {
        if (this.$refs.pondconsul) {
          const lenghtPondCons = this.$refs.pondconsul.getFiles().length;
          for (let i = 0; i < lenghtPondCons; i++) {
            this.$refs.pondconsul.removeFile(i);
          }
        }
      }

      this.denuncia = {
        denu_tipo_denuncia: "anonymous",
        denu_nombre: "",
        denu_apellidos: "",
        denu_telefono: "",
        denu_email: "",
        denu_email2: "",
        denu_organizacion: "",
        denu_relacion_cd: 0,
        denu_consulta: "",
        denu_tipo_delito: "",
        denu_personas: "",
        denu_departamento: 0,
        denu_sede: 0,
        denu_fuente: "",
        denu_empresa_cd: "",
        denu_naturaleza_cd: 0,
        denu_tipo_dir: "email"
      };
      this.formError = false;
      this.privacitypolicy = false;
    },
    ...mapMutations(["littleDrop", "changeBreadCrumb", "changeLogo"]),
    verImagen(id_emp) {
      const logo = `${this.servidor}api/ver_logotipo.asp?emp_id=${id_emp}`;
      this.changeLogo(logo);
    },
    formatDatas() {
      let formData = new FormData();
      this.denuncia.denu_empresa_cd = this.emp_id;
      this.denuncia.denu_fuente = this.emp_canal;
      formData.append("denu_empresa_cd", this.denuncia.denu_empresa_cd);
      formData.append("denu_fuente", this.denuncia.denu_fuente);
      if (this.tipo === "consultation") {
        this.denuncia.denu_tipo_denuncia = "CONSULTA";
      } else if (this.tipo === "complaint") {
        if (this.anonymous) {
          this.denuncia.denu_tipo_denuncia = "ANONIMO";
        } else {
          this.denuncia.denu_tipo_denuncia = "NOMINAL";
        }
      }
      formData.append(
        "denu_tipo_denuncia",
        this.denuncia.denu_tipo_denuncia.toUpperCase()
      );
      formData.append("denu_naturaleza_cd", this.denuncia.denu_naturaleza_cd);

      // if (this.denuncia.denu_tipo_denuncia !== "ANONIMO") {
      formData.append("denu_nombre", this.denuncia.denu_nombre.toUpperCase());
      formData.append(
        "denu_apellidos",
        this.denuncia.denu_apellidos.toUpperCase()
      );
      formData.append(
        "denu_telefono",
        this.denuncia.denu_telefono.toUpperCase()
      );
      formData.append("denu_email", this.denuncia.denu_email.toUpperCase());
      formData.append("denu_email2", this.denuncia.denu_email2.toUpperCase());
      formData.append("denu_tipo_dir", this.denuncia.denu_tipo_dir.toUpperCase());
      // }

      // if (this.denuncia.denu_tipo_denuncia !== "CONSULTA") {
      formData.append(
        "denu_tipo_delito",
        this.denuncia.denu_tipo_delito.toUpperCase()
      );
      formData.append(
        "denu_personas",
        this.denuncia.denu_personas.toUpperCase()
      );
      formData.append("denu_departamento", this.denuncia.denu_departamento);
      formData.append("denu_sede", this.denuncia.denu_sede);
      // }

      formData.append(
        "denu_organizacion",
        this.denuncia.denu_organizacion.toUpperCase()
      );
      formData.append("denu_relacion_cd", this.denuncia.denu_relacion_cd);
      formData.append(
        "denu_consulta",
        this.denuncia.denu_consulta.toUpperCase()
      );

      if (this.tipo === "complaint") {
        for (let i = 0; i < this.$refs.pond.getFiles().length; i++) {
          if (this.$refs.pond.getFile(i)) {
            formData.append(
              `denu_archivo${i}`,
              this.$refs.pond.getFile(i).file
            );
          } else {
            formData.append(`denu_archivo${i}`, "");
          }
        }
      } else {
        for (let i = 0; i < this.$refs.pondconsul.getFiles().length; i++) {
          if (this.$refs.pondconsul.getFile(i)) {
            formData.append(
              `denu_archivo${i}`,
              this.$refs.pondconsul.getFile(i).file
            );
          } else {
            formData.append(`denu_archivo${i}`, "");
          }
        }
      }
      if (this.denuncia.denu_cuando) {
        formData.append("denu_cuando", this.denuncia.denu_cuando.toUpperCase());
      } else {
        formData.append("denu_cuando", "");
      }

      formData.append("denu_reiterativo", this.denuncia.denu_reiterativo);
      formData.append("denu_miembro", this.denuncia.denu_miembro);
      return formData;
    },
    validateForm() {
      if (!this.privacitypolicy) {
        this.formError = true;
        return false;
      }
      if (!this.anonymous) {
        if (this.denuncia.denu_nombre === "") {
          this.formError = true;
          return false;
        }
        if (!this.isEmailValid()) {
          this.formError = true;
          return false;
        }
        if (this.denuncia.denu_apellidos === "") {
          this.formError = true;
          return false;
        }
      }

      if (this.denuncia.denu_relacion_cd === 0) {
        this.formError = true;
        return false;
      }

      if (this.denuncia.denu_consulta === "") {
        this.formError = true;
        return false;
      }

      if (this.tipo !== "consultation") {
        if (this.denuncia.denu_personas === "") {
          this.formError = true;
          return false;
        }
        
      }
      this.formError = false;
      return true;
    },
    isEmailValid() {
      if (1 == 1) {
        return this.denuncia.denu_email == ""
          ? ""
          : this.reg.test(this.denuncia.denu_email)
          ? true
          : false;
      } else {
        return true;
      }
    },
    changeType(type) {
      this.tipo = type;
      this.cleanForm();
      if (this.tipo !== "consultation") {
        this.anonymous = false;
      } else {
        this.anonymous = true;
      }
    },
    async getColorType() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?ac=color&id=${this.emp_id}`
        );
        const color = res.data.emp_color;
        this.emp_nombre = res.data.emp_nombre;
        this.emp_cif = res.data.emp_cif;        
        if (color === 0) {
          this.changeColorCompanyOrange();
        } else if (color === 1) {
          this.changeColorCompanyBlue();
        } else if (color === 2) {
          this.changeColorCompanyGreen();
        } else if (color === 3) {
          this.changeColorCompanyRed();
        } else if (color === 4) {
          this.changeColorCompany4();
        } else if (color === 5) {
          this.changeColorCompany5();
        }else if (color === 6) {
          this.changeColorCompany6();
        }else if (color === 7) {
          this.changeColorCompany7();
        }else if (color === 8) {
          this.changeColorCompany8();
        }else if (color === 9) {
          this.changeColorCompany9();
        }else if (color === 10) {
          this.changeColorCompany10();
        }else if (color === 11) {
          this.changeColorCompany11();
        }else if (color === 12) {
          this.changeColorCompany12();
        }else if (color === 13) {
          this.changeColorCompany13();
        }else if (color === 14) {
          this.changeColorCompany14();
        }else if (color === 15) {
          this.changeColorCompany15();
        }else if (color === 16) {
          this.changeColorCompany16();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
}

.form {
  text-align: left;
}
#sendBtn {
  float: right;
}

.button {
  padding: 10px;
  width: 200pt;
  border-radius: 5px;
  color: #fff;
}

.header {
  text-align: center;
  margin-top: 20pt;
}

.headerPrinc {
  padding-top: 60pt;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  margin-top: 40pt;
}

.headerPrinc-mobile {
  padding-top: 60pt;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
}

.inputForm {
  margin-bottom: 40px;
  height: 35pt;
}

input {
  margin-bottom: 40px;
  height: 35pt;
}

#btnSend {
  background-color: #666666;
  border-bottom-right-radius: 0pt;
  border-top-right-radius: 0pt;
  width: 79%;
}

#btnClean {
  border-bottom-left-radius: 0pt;
  border-top-left-radius: 0pt;
  width: 20%;
  background-color: #a4a9a8;
}

a {
  color: black;
}

#cardForm {
  /* margin-top: 100pt; */
  padding: 25pt;
  display: block;
  margin-bottom: 40px;
}

#cardForm2 {
  margin-top: 20pt;
  padding: 25pt;
  display: block;
  margin-bottom: 40px;
}

select {
  color: #898f96;
  height: 35pt;
  margin-bottom: 40px;
}

.buttonClose {
  color: #fff;
  background-color: #666666;
}

.header2 {
  margin-top: var(--margin-top);
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.wrappermobile {
  display: block;
  width: 100%;
  align-items: stretch;
}

.footer-legal {
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  height: 46pt;
  padding: 5pt;
}

.a-legal {
  color: black;
}
.a-legal2 {
  color: #fff;
}
.fuenteblanca {
  color:#fff;
}
</style>