<template>
  <div class="footer" v-if="active">
    <p style="line-height: 20px;">
      {{ $t("legal.cookieNotice") }} <a style="color: black" href="/politica-cookies.asp" target="_blank">{{ $t("legal.cookieNoticeRef" )}}</a>
      <button class="button" @click="acceptCookies">{{ $t("actions.confirm") }}</button>
    </p>
  </div>
</template>

<script>
import { VueCookieNext } from "vue-cookie-next";

export default {
  data() {
    return {
      active: true,
      hrefPrivacy: "",
    };
  },
  mounted() {
    if (VueCookieNext.getCookie("cookie")) {
      this.active = false;
    }
  },
  methods: {
    acceptCookies() {
      this.active = false;
      VueCookieNext.setCookie("cookie", true, { expire: "1d" });
    },
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: black;
  text-align: center;
  min-height: 50pt;
  padding: 5pt;
}

.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #6c757d;
  border-color: #6c757d;
  margin-left: 5pt;
}
</style>