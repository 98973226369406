import { createStore } from 'vuex'

export default createStore({
    state: {
        logo: "",
        id_emp: "",
        id_usu: "",
        usu_rol: 0,
        navbar: true,
        allDrop: false,
        breadCrumb: 0,
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        bigmenu: false,
        colorCompany: "#666666",
        activeLogo: true,
        //servidor: "http://localhost:8080/"
        servidor: "https://denuncias.servicios-empresa.com/"
            //servidor: "http://192.168.1.111/"

    },
    mutations: {
        deactiveNavbar(state) {
            state.navbar = false;
        },
        activeNavbar(state) {
            state.navbar = true;
        },
        littleDrop(state) {
            state.allDrop = true;
        },
        bigDrop(state) {
            state.allDrop = false;
        },
        changeBreadCrumb(state, payload) {
            state.breadCrumb = payload;
        },
        changeIdEmp(state, payload) {
            state.id_emp = payload;
        },
        changeLogo(state, payload) {
            console.log("STORELGO", payload)
            state.logo = payload;
        },
        changeIdUsu(state, payload) {
            state.id_usu = payload;
        },
        changeTipoUsu(state, payload) {
            state.usu_rol = payload;
        },
        changeBigmenu(state, payload) {
            state.bigmenu = payload;
        },
        changeColorCompanyOrange(state) {
            state.colorCompany = "#666666";
        },
        changeColorCompanyGreen(state) {
            state.colorCompany = "#6ed966";
        },
        changeColorCompanyRed(state) {
            state.colorCompany = "#d53009";
        },
        changeColorCompanyBlue(state) {
            state.colorCompany = "#82dcce";
        },
        changeColorCompany4(state) {
            state.colorCompany = "#008193";
        },
        changeColorCompany5(state) {
            state.colorCompany = "#00A0D1";
        },
        changeColorCompany6(state) {
            state.colorCompany = "#001489";
        },
        changeColorCompany7(state) {
            state.colorCompany = "#E4002B";
        },
        changeColorCompany8(state) {
            state.colorCompany = "#7C878E";
        },
        changeColorCompany9(state) {
            state.colorCompany = "#0E0673";
        },
        changeColorCompany10(state) {
            state.colorCompany = "#02B735";
        },
        changeColorCompany11(state) {
            state.colorCompany = "#5DE1E6";
        },
        changeColorCompany12(state) {
            state.colorCompany = "#858585";
        },
        changeColorCompany13(state) {
            state.colorCompany = "#92773D";
        },
        changeColorCompany14(state) {
            state.colorCompany = "#8EF76D";
        },
        changeColorCompany15(state) {
            state.colorCompany = "#066841";
        },
        changeColorCompany16(state) {
            state.colorCompany = "#0087be";
        }
    },
    actions: {
        async superuserCrud(data, table, action) {
            try {
                const res = await axios.post(
                    `${this.servidor}api/op.asp?tp=${table}&ac=${action}`,
                    data
                );
                console.log(res);
                $("#responseModal").modal("show");
                return res.data.RESULT;
            } catch (error) {
                console.error(error);
                $("#responseModal").modal("show");
                return "1";
            }
        },
        isEmailValid(email) {
            return email == "" ?
                "" :
                this.reg.test(email) ?
                true :
                false;
        }
    },
    getters: {

    },
    modules: {}
})