<template>
  <!-- As a heading -->
  <nav
    v-if="navbar && !device"
    class="navbar navbar-light"
    style="background-color: white;position: fixed;width: 100%;top: 0;z-index: 100;"
  >
    <div class="container-fluid">
      <div class="comp-navbar">
        <img
          class="img-logo"
          v-if="!logo"
          src="@/assets/img/logo-image.png"
          alt="logo"
        />
        <img class="img-logo" v-else :src="logo" />
        <!-- <img class="img-logo" src="../assets/img/logo-ad.png" /> -->

      </div>

      <div class="d-flex">
        <span style="font-size: 11pt;color:#000000;">
          <!-- <span style="font-weight: bold">{{ breadCrumb }}</span> -->
          <span v-if="breadCrumb === 0" style="font-weight: bold">{{
            $t("forms.principalBreadCrumb")
          }}</span>
          <!-- Super user -->
          <span v-if="breadCrumb === 1" style="font-weight: bold">{{
            $t("maintenance.companies.title")
          }}</span>
          <span v-if="breadCrumb === 2" style="font-weight: bold">{{
            $t("maintenance.countries.title")
          }}</span>
          <span v-if="breadCrumb === 3" style="font-weight: bold">{{
            $t("maintenance.fonts.title")
          }}</span>
          <span v-if="breadCrumb === 4" style="font-weight: bold">{{
            $t("maintenance.languages.title")
          }}</span>
          <span v-if="breadCrumb === 5" style="font-weight: bold">{{
            $t("maintenance.natures.title")
          }}</span>
          <span v-if="breadCrumb === 6" style="font-weight: bold">{{
            $t("maintenance.relations.title")
          }}</span>
          <span v-if="breadCrumb === 7" style="font-weight: bold">{{
            $t("maintenance.superusers.title")
          }}</span>
          <span v-if="breadCrumb === 8" style="font-weight: bold">{{
            $t("login.title")
          }}</span>
          <span v-if="breadCrumb === 9" style="font-weight: bold">{{
            $t("login.titleRestore")
          }}</span>
          <span v-if="breadCrumb === 23" style="font-weight: bold">{{
            $t("mCompany.statistic.title")
          }}</span>

          <!-- Companies -->
          <span v-if="breadCrumb === 10" style="font-weight: bold">{{
            $t("login.titleEmp")
          }}</span>
          <span v-if="breadCrumb === 11" style="font-weight: bold">{{
            $t("login.titleRestore")
          }}</span>
          <span v-if="breadCrumb === 12" style="font-weight: bold">{{
            $t("mCompany.mylink.title")
          }}</span>
          <span v-if="breadCrumb === 13" style="font-weight: bold">{{
            $t("mCompany.departments.title")
          }}</span>
          <span v-if="breadCrumb === 14" style="font-weight: bold">{{
            $t("mCompany.headquarters.title")
          }}</span>
          <span v-if="breadCrumb === 15" style="font-weight: bold">{{
            $t("mCompany.users.title")
          }}</span>
          <span v-if="breadCrumb === 16" style="font-weight: bold">{{
            $t("mCompany.statistic.title")
          }}</span>
          <span v-if="breadCrumb === 32" style="font-weight: bold">{{
            $t("menu.config")
          }}</span>

          <!-- Users -->
          <span v-if="breadCrumb === 17" style="font-weight: bold">{{
            $t("login.titleUser")
          }}</span>
          <span v-if="breadCrumb === 18" style="font-weight: bold">{{
            $t("login.titleRestore")
          }}</span>
          <span v-if="breadCrumb === 19" style="font-weight: bold">{{
            $t("mUser.mydata.title")
          }}</span>
          <span v-if="breadCrumb === 20" style="font-weight: bold">{{
            $t("mUser.chistory.title")
          }}</span>
          <span v-if="breadCrumb === 21" style="font-weight: bold">{{
            $t("mUser.ocases.title")
          }}</span>
          <span v-if="breadCrumb === 25" style="font-weight: bold">{{
            $t("mUser.ocases.titleAnalysis")
          }}</span>
          <span v-if="breadCrumb === 26" style="font-weight: bold">{{
            $t("mUser.ocases.titleDecision")
          }}</span>
          <span v-if="breadCrumb === 27" style="font-weight: bold">{{
            $t("mUser.ocases.titleComunication")
          }}</span>
          <span v-if="breadCrumb === 28" style="font-weight: bold">{{
            $t("mUser.ocases.titleAnalysis")
          }}</span>
          <span v-if="breadCrumb === 29" style="font-weight: bold">{{
            $t("mUser.ocases.titleDecision")
          }}</span>
          <span v-if="breadCrumb === 30" style="font-weight: bold">{{
            $t("mUser.ocases.titleComunication")
          }}</span>

          <!-- Whistleblower -->
          <span v-if="breadCrumb === 22" style="font-weight: bold">{{
            $t("search.title")
          }}</span>
          <span v-if="breadCrumb === 31" style="font-weight: bold">{{
            $t("search.whistleblower")
          }}</span>

          <!-- Notices -->
          <span v-if="breadCrumb === 24" style="font-weight: bold">{{
            $t("menu.noticemin")
          }}</span>

          <!-- Change password -->
          <span v-if="breadCrumb === 33" style="font-weight: bold">{{
            $t("menu.changep")
          }}</span>
        </span>        
        <span class="nav-item dropdown comp-navbar">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="color: black"
          >
            <i class="fas fa-cog" style="color:#000000;"></i>
          </a>
          <ul
            class="dropdown-menu dropdown-list"
            aria-labelledby="navbarDropdown"
          >
            <ChangeLanguage />
            <hr v-if="!allDrop" />
            <li v-if="!allDrop" style="margin-top: -4pt" @click="logout">
              <span class="dropdown dropicon"
                ><i class="fas fa-sign-out-alt"></i>
                {{ $t("forms.signout") }}</span
              >
            </li>            
          </ul>
        </span>
      </div>
    </div>
  </nav>

  <nav
    v-if="navbar && device"
    class="navbar navbar-light"
    style="background-color: white; width: 100%"
  >
    <div class="container-fluid">
      <div class="comp-navbar">
        <img class="img-logo-main-mobile" src="../assets/img/logo_app.png" />
      </div>

      <div class="d-flex">
        <span class="nav-item dropdown comp-navbar">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="color: black"
          >
            <i class="fas fa-cog"></i>
          </a>
          <ul
            class="dropdown-menu dropdown-list-mobile"
            aria-labelledby="navbarDropdown"
          >
            <ChangeLanguage />
            <!-- <hr v-if="!allDrop" /> -->
            <!-- <li v-if="!allDrop" style="margin-top: -4pt" @click="logout">
              <span class="dropdown dropicon"
                ><i class="fas fa-sign-out-alt"></i>
                {{ $t("forms.signout") }}</span
              >
            </li> -->
          </ul>
        </span>
        <img
          class="img-logo-mobile"
          v-if="!logo"
          src="@/assets/img/logo-image.png"
          alt="logo"
        />
        <img class="img-logo-mobile" v-else :src="logo" />
        <!-- <img class="img-logo" src="../assets/img/logo-ad.png" /> -->
      </div>
    </div>
  </nav>
</template>

<script>
import ChangeLanguage from "./ChangeLanguage";
import { mapState } from "vuex";

export default {
  props: ["device"],
  computed: mapState([
    "navbar",
    "allDrop",
    "breadCrumb",
    "id_emp",
    "logo",
    "servidor",
    "colorCompany",
  ]),
  components: { ChangeLanguage },
  data() {
    return {
      // logo: "",
      emp_smtp: {},
    };
  },
  // computed() {
  //   this.verImagen();
  // },
  methods: {
    verImagen() {
      console.log(this.id_emp);
      if (this.id_emp) {
        this.logo = `${this.servidor}api/ver_logotipo.asp?emp_id=${this.id_emp}`;
      }
      console.log(this.logo);
    },
    logout() {
      const path = this.$route.path;
      const logout = `${this.servidor}api/cerrar_sesion.asp`;
      console.log(logout);
      if (path.includes("adminsu")) {
        this.$router.push("/adminsu/login");
      } else if (path.includes("admin")) {
        this.$router.push("/admin/login");
      } else if (path.includes("user")) {
        const idemp = path.split("/")[1];
        this.$router.push(`/${idemp}/user/login`);
      }
    },
    configuration() {
      $("#configuration").modal("show");
    },
    sendConfiguration() {
      $("#configuration").modal("hide");
    },
  },
};
</script>

<style scoped>
.dropdown {
  font-weight: bold;
  padding: 10pt;
  font-size: 10pt;
}

.dropicon {
  margin-left: 5pt;
}

.dropdown-list {
  top: 35pt;
  left: -71pt;
}

.dropdown-list-mobile {
  top: 28pt;
  left: -9pt;
}

li {
  cursor: pointer;
}

.comp-navbar {
  display: flex;
  align-items: center;
}

.img-logo {
  max-width: 280px;
  max-height: 80px;
}

i {
  font-size: 16pt;
}

.img-logo-main {
  width: 140pt;
  padding: 10pt;
}

.img-logo-main-mobile {
  width: 100pt;
  padding: 10pt;
}

.img-logo-mobile {
  max-width: 100pt;
  max-height: 80px;
}

.legal {
  margin-left: 32pt;
  color: black;
}
</style>