{
    "actions": {
        "close": "Cerrar",
        "send": "Enviar",
        "toattach": "Documentación/Evidencia",
        "save": "Guardar",
        "clean": "Limpiar",
        "singin": "Iniciar sesión",
        "restore": "Recuperar",
        "return": "Volver",
        "confirm": "Aceptar",
        "cancel": "Cancelar",
        "access": "Acceder",
        "create": "Crear",
        "cerrarFase": "Cerrar fase"
    },
    "forms": {
        "title": "Canal de Denuncias",
        "titlef1": "¡Bienvenidos a nuestro canal de denuncias, un espacio dedicado al cumplimiento de la ley 2/2023 de 20 de febrero en España!",
        "titlef2": "En este canal, nos comprometemos a ser un vehículo confiable para aquellos que desean hacer valer sus derechos y contribuir a la justicia. La Ley 2/2023 establece las normativas fundamentales que rigen nuestro comportamiento como sociedad, y nuestro objetivo es asegurarnos de que se cumplan de manera rigurosa.",
        "titlef3": "Nos enfocamos en brindar a todos la oportunidad de informar cualquier infracción o irregularidad que observen en su entorno, ya sea en el ámbito público o privado. Creemos en la importancia de mantener una comunidad justa y ética, donde todos puedan vivir y trabajar de acuerdo con los principios establecidos por la ley.",
        "titlef4": "Nuestro equipo está comprometido con la confidencialidad y la seriedad en el manejo de cada denuncia recibida. Trabajamos en colaboración con las autoridades correspondientes para garantizar que cada caso sea tratado con la atención y la importancia que merece.",
        "titlef5": "Juntos, construimos un espacio donde la transparencia y la legalidad son valores fundamentales. Al unir fuerzas, contribuimos a la construcción de una sociedad más justa y respetuosa de las leyes que la rigen.",
        "titlef6": "Gracias por unirte a nosotros en este viaje hacia un entorno más seguro, justo y respetuoso. Tu participación es crucial para el éxito de nuestra misión. ¡Denunciemos juntos para construir un futuro más cumplidor y ético!",
        "titlef7": "Para más información sobre el canal pinche ",
        "fraseinicial": "MUY IMPORTANTE: Si la denuncia puede afectar al Administrador de la sociedad o alguno de los miembros del Comité Ético, por favor utilice siempre el formulario de denuncia ANONIMA. Adicionalmente, puede dirigirse a nosotros para conocer más sobre nuestro Comité Ético y el resto de nuestro Sistema de Compliance, a través de las vías ordinarias de contacto con nuestra organización.",
        "subtitle": "Aquí puede comunicarnos incidencias o plantearnos dudas relativas a incumplimientos legales, incumplimientos de nuestras directrices y procedimientos o incumplimientos de cualquier otro tipo",
        "subtitle2": "Este no es un servicio de emergencia",
        "complaintsChannel": "Canal de Denuncias",
        "consult": "Consulta",
        "anonymous": "Anónima",
        "nominal": "Nominal",
        "complaint": "Denuncia",
        "finalMesssage": "Usted puede consultar el estado de la misma a través de la url: ",
        "finalMesssage2": "Introduciendo el siguiente código: ",
        "finalMessage3": "Puede imprimir una copia o guardar como pdf haciendo ",
        "finalDenuncia": "Si desea hacer un seguimiento de la evolución de la denuncia debe guardar el enlace y el código que aparecen en pantalla.",
        "finalDenuncia2": "Le recomendamos que acceda a su denuncia con frecuencia para comprobar si nos hemos puesto en contacto con usted.",
        "typeChannel": "¿Qué desea hacer?",
        "typeComplaint": "Tipo de delito",
        "answer": "Respuesta",
        "email": "Correo electrónico",
        "name": "Nombre",
        "user": "Usuario",
        "password": "Contraseña",
        "privacity": "He leído y acepto la ",
        "privacity2": "He leído la ",
        "capa1_GEN": "Resp. Tratamiento: ",
        "capa2_GEN": ". Finalidad: Seguimiento, gestión y resolución de la solicitud recibida a través del canal ético. Ejercicio de derechos y más información sobre el tratamiento de los datos: ",
        "capa1_1": "De conformidad con las normativas de protección de datos, le facilitamos la siguiente información del tratamiento:",
        "capa1_2": "Responsable:  HEALTH TIME, S.L. (EN ADELANTE HT MEDICA).",
        "capa1_3": "Fines del tratamiento: gestionar adecuadamente nuestro canal ético, tramitando las correspondientes irregularidades notificadas a través del mismo",
        "capa1_4": "Derechos que le asisten: acceso, rectificación, portabilidad, supresión, limitación y oposición",
        "capa1_5": "Más información del tratamiento en nuestra política de privacidad",
        "capa1_6": "Su consentimiento es necesario para el tratamiento posterior de estos datos en el procedimiento y sin los cuales no sería posible tramitar su solicitud.",
        "anonimato": "Para mantener el anonimato, se recomienda solo subir archivos en formato pdf ",
        "policy": "Política de Privacidad",
        "consultation": {
            "name": "Nombre",
            "surname": "Apellidos",
            "phone": "Teléfono",
            "email": "Correo electrónico",
            "ordinario": "Correo ordinario",
            "otromedia": "¿Otro medio de comunicación?",
            "anompo": "Anonimizado por ",
            "mediocom": "Medio comunicación",
            "otros": "Otros",
            "organization": "Organización a la que pertenece",
            "relationship": "¿Qué relación tiene?",
            "message": "¿Cuál es su consulta/denuncia?",
            "nature": "Naturaleza de la consulta/denuncia",
            "notificacionMensaje": "Recibir notificaciones por ",
            "bloquear": "¿Está el Responsable del Canal (Compliance Officer) o el Órgano de Gobierno involucrado en los hechos denunciados? ",
            "bloquearEsp": "¿Está el Responsable del Canal o el Rol Suplente involucrado en los hechos denunciados? ",
            "bloqueoOrganoEsp": "Si, el Rol Suplente (NO recibirá notificación de la denuncia)",
            "bloqueoOrgano": "Si, el Órgano de Gobierno (NO recibirá notificación de la denuncia)",
            "bloqueoOffice": "Si, el Compliance Officer (NO recibirá notificación de la denuncia)",
            "bloqueoOfficeEsp": "Si, el Responsable del Canal (NO recibirá notificación de la denuncia)",
            "defajenos": "Su denuncia va a ser anonimizada por incluir datos personales ajenos a los hechos denunciados. Por favor, envíe de nuevo la denuncia sin incluir esos datos.",
            "defsensibles": "Su denuncia va a ser anonimizada por incluir datos personales pertenecientes a las categorías especiales de datos. Por favor, envíe de nuevo la denuncia sin incluir esos datos."
        },
        "comp": {
            "whatComp": "¿Cuál es su denuncia?",
            "involved": "Persona/s implicada/s",
            "involved2": "Si desconoce la identidad de la/s persona/s implicada/s escriba `Lo desconozco`",
            "department": "Departamento afectado",
            "headquarters": "Sede afectada",
            "otherComplaints": "Descripción de la denuncia",
            "other": "Detalle otros delitos",
            "nature": "Naturaleza de la denuncia",
            "when": "¿Cuándo ha ocurrido el incidente?",
            "reiterativo": "¿El incidente es reiterativo?",
            "yes": "Si",
            "no": "No",
            "bloqmiembro": "Bloquear Miembro"
        },
        "principalBreadCrumb": "Canal de Denuncias",
        "profile": "Ver perfil",
        "settings": "Configuración",
        "contact": "Contacto",
        "signout": "Cerrar sesión",
        "imgText": "Arrastre y suelte los documentos o pulse aquí"
    },
    "login": {
        "user": "Nombre de usuario",
        "password": "Contraseña",
        "forgot": "¿No recuerda su contraseña?",
        "title": "Identificación de super usuario",
        "titleEmp": "Identificación de empresa",
        "titleUser": "Identificación de usuario",
        "restore": "Recuperar",
        "titleRestore": "Recuperar contraseña",
        "finalMesssage": "Se ha recuperado su contraseña.",
        "restoreFail": "Ha ocurrido un error, intentelo de nuevo.",
        "email": "Correo electrónico",
        "errorUsu": "Nombre de usuario o contraseña incorrectos",
        "errorBlock": "El usuario está bloqueado, póngase en contacto con un administrador para desbloquearlo",
        "restoreFailEmail": "El formato del email no es válido"
    },
    "error": {
        "name": "El nombre es un campo obligatorio",
        "user": "El usuario es un campo obligatorio"
    },
    "menu": {
        "complaintsChannel": "Complaints Channel",
        "mcompany": "CREAR EMPRESA",
        "msuperuser": "CREAR SUPERUSUARIO",
        "roleMain": "MANTENIMIENTO de roles",
        "countryMain": "MANTENIMIENTO DE PAÍSES",
        "offenseMain": "MANTENIMIENTO DE DELITOS",
        "mainLanguages": "MANTENIMIENTO DE IDIOMAS",
        "fontMain": "MANTENIMIENTO DE FUENTES",
        "relation": "MANTENIMIENTO DE RELACIONES",
        "nature": "MANTENIMIENTO DE NATURALEZAS",
        "headquarters": "MANTENIMIENTO DE SEDES",
        "departments": "MANTENIMIENTO DE DEPARTAMENTOS",
        "users": "MANTENIMIENTO DE USUARIOS",
        "mydata": "MIS DATOS",
        "statistics": "ESTADÍSTICAS",
        "whistleblower": "DENUNCIANTE",
        "history": "HISTORIAL DE CASOS",
        "ocases": "CASOS ABIERTOS",
        "title": "Hola",
        "mylinks": "MIS ENLACES",
        "notice": "MIS AVISOS",
        "noticemin": "Mis avisos",
        "searcher": "BUSCADOR",
        "config": "CONFIGURACIÓN",
        "changep": "CAMBIO DE CONTRASEÑA"
    },
    "maintenance": {
        "actions": {
            "add": "Agregar",
            "cancel": "Cancelar",
            "confirm": "Aceptar"
        },
        "languages": {
            "title": "Mantenimiento de idiomas",
            "name": "Nombre",
            "key": "Código",
            "deleteTitle": "Borrando idioma {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el idioma?",
            "deleteConfirmTitle": "El idioma ha sido eliminado",
            "deleteConfirm": "El idioma ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando idioma {name}",
            "editMessage": "El idioma ha sido editado",
            "addTitle": "Agregando nuevo idioma"
        },
        "countries": {
            "title": "Mantenimiento de países",
            "name": "Nombre",
            "id": "Código",
            "deleteTitle": "Borrando país {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el país?",
            "deleteConfirmTitle": "El país ha sido eliminado",
            "deleteConfirm": "El país ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando país {name}",
            "editMessage": "El país ha sido editado",
            "addTitle": "Agregando nuevo país"
        },
        "natures": {
            "title": "Mantenimiento de naturalezas",
            "name": "Nombre",
            "deleteTitle": "Borrando naturaleza {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la naturaleza?",
            "deleteConfirmTitle": "La naturaleza ha sido eliminada",
            "deleteConfirm": "La naturaleza ha sido eliminada",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando naturaleza {name}",
            "editMessage": "La naturaleza ha sido editada",
            "addTitle": "Agregando nueva naturaleza"
        },
        "relations": {
            "title": "Mantenimiento de relaciones",
            "name": "Nombre",
            "deleteTitle": "Borrando relación {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la relación?",
            "deleteConfirmTitle": "La relación ha sido eliminada",
            "deleteConfirm": "La relación ha sido eliminada",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando relación {name}",
            "editMessage": "La relación ha sido editada",
            "addTitle": "Agregando nueva relación"
        },
        "superusers": {
            "title": "Super usuario",
            "name": "Nombre",
            "id": "Código",
            "email": "Correo electrónico",
            "password": "Contraseña",
            "state": "Estado",
            "deleteTitle": "Borrando super usuario {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el super usuario?",
            "deleteConfirmTitle": "El super usuario ha sido eliminado",
            "deleteConfirm": "El super usuario ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando super usuario {name}",
            "editMessage": "El super usuario ha sido editado",
            "addTitle": "Agregando nuevo super usuario",
            "lock": "Desbloquear"
        },
        "companies": {
            "title": "Empresas",
            "name": "Nombre",
            "cif": "CIF",
            "email": "Correo electrónico",
            "password": "Contraseña",
            "direction": "Dirección",
            "population": "Población",
            "cp": "CP",
            "user": "Usuario",
            "province": "Provincia",
            "group": "¿A qué grupo pertenece?",
            "logo": "Logotipo",
            "designType": "Tipo de diseño",
            "companyType": "Tipo de empresa",
            "lang": "Idioma",
            "state": "Estado",
            "single": "Única",
            "iGroup": "¿Es grupo?",
            "belongs": "Pertenece",
            "country": "País",
            "isGroup": "¿Pertenece a un grupo?",
            "is-group": "Grupo?",
            "deleteTitle": "Borrando empresa {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la empresa?",
            "deleteConfirmTitle": "La empresa ha sido eliminado",
            "deleteConfirm": "La empresa ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando empresa {name}",
            "editMessage": "La empresa ha sido editado",
            "addTitle": "Agregando nueva empresa",
            "imgText": "Arrastre y suelte la imagen o pulse aquí",
            "unlock": "Desbloquear",
            "lock": "Bloquear",
            "link": "Link canal denuncias:",
            "typeRed": "Tipo rojo",
            "typeBlue": "Tipo azul",
            "typeGreen": "Tipo verde",
            "typeOrange": "Tipo naranja"
        },
        "fonts": {
            "title": "Mantenimiento de fuentes",
            "name": "Nombre",
            "deleteTitle": "Borrando relación {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la fuente?",
            "deleteConfirmTitle": "La fuente ha sido eliminada",
            "deleteConfirm": "La fuente ha sido eliminada",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando fuente {name}",
            "editMessage": "La fuente ha sido editada",
            "addTitle": "Agregando nueva fuente"
        }
    },
    "mCompany": {
        "users": {
            "title": "Mantenimiento de usuarios",
            "name": "Nombre",
            "password": "Contraseña",
            "email": "Correo electrónico",
            "dni": "DNI",
            "user": "Usuario",
            "governingBody": "Órgano de gobierno",
            "compliance": "Compliance Officer",
            "rol": "Seleccionar un rol",
            "rolTable": "Rol",
            "deleteTitle": "Borrando usuario {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el usuario?",
            "deleteConfirmTitle": "El usuario ha sido eliminado",
            "deleteConfirm": "El usuario ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando usuario {name}",
            "editMessage": "El usuario ha sido editado",
            "addTitle": "Agregando nuevo usuario",
            "state": "Estado",
            "signature": "¿Tiene firma digital?"
        },
        "departments": {
            "title": "Mantenimiento de departamentos",
            "name": "Nombre",
            "deleteTitle": "Borrando departamento {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el departamento?",
            "deleteConfirmTitle": "El departamento ha sido eliminado",
            "deleteConfirm": "El departamento ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando departamento {name}",
            "editMessage": "El departamento ha sido editado",
            "addTitle": "Agregando nuevo departamento"
        },
        "headquarters": {
            "title": "Mantenimiento de sedes",
            "name": "Nombre",
            "deleteTitle": "Borrando sede {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la sede?",
            "deleteConfirmTitle": "La sede ha sido eliminado",
            "deleteConfirm": "La sede ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando sede {name}",
            "editMessage": "La sede ha sido editado",
            "addTitle": "Agregando nuevo sede"
        },
        "mylink": {
            "title": "Mis links",
            "linkForms": "Enlaces a formularios",
            "linkUser": "Enlaces a inicio sesión de usuario",
            "linkWhistleblower": "Enlace estado de tu consulta"
        },
        "statistic": {
            "title": "Mis estadísticas"
        }
    },
    "mUser": {
        "mydata": {
            "title": "Mis datos"
        },
        "chistory": {
            "title": "Historial de casos",
            "titleAnalysis": "Historial de casos > Análisis",
            "titleDecision": "Historial de casos > Decisión",
            "titleComunication": "Historial de casos > Comunicación",
            "date": "Fecha",
            "type": "Tipo",
            "user": "Cerrado por",
            "code": "Código",
            "governingBody": "Órgano de gobierno",
            "compliance": "Compliance Officer",
            "consult": "Consulta",
            "anonymous": "Anónima",
            "nominal": "Nominal",
            "case": "CASO",
            "seecomunication": "Denunciante"
        },
        "ocases": {
            "title": "Casos abiertos",
            "time": "Plazo",
            "intime": "En plazo",
            "outtime": "Fuera de plazo",
            "titleAnalysis": "Casos abiertos > Análisis",
            "titleDecision": "Casos abiertos > Decisión",
            "titleComunication": "Casos abiertos > Comunicación",
            "date": "Fecha",
            "type": "Tipo",
            "nature": "Naturaleza",
            "phase": "Fase",
            "user": "Asignado a",
            "code": "Código",
            "governingBody": "Órgano de gobierno",
            "compliance": "Compliance Officer",
            "consult": "Consulta",
            "anonymous": "Anónima",
            "nominal": "Nominal",
            "danonymous": "Denuncia Anónima",
            "dnominal": "Denuncia Nominal",
            "isCrime": "¿Hay algún delito relacionado con la consulta/denuncia?",
            "crimes": "Introduzca el/los delitos asociados",
            "investigation": "Investigaciones",
            "author": "Autor",
            "uploadFile": "Archivos subidos por el denunciante",
            "files": {
                "name": "Nombre",
                "date": "Fecha",
                "download": "Descargar"
            },
            "authors": {
                "name": "Nombre",
                "date": "Fecha"
            },
            "close": "Cerrar consulta",
            "closedenu": "Cerrar fase",
            "invPhase": {
                "title": "Investigación fase",
                "text": "Respuesta",
                "cause": "Análisis de las causas",
                "observation": "Observaciones",
                "relevant": "¿Es pertinente?",
                "conclusionPhase2": "Conclusión final",
                "yes": "Si",
                "no": "No"
            },
            "falsa": "Denuncia Falsa",
            "avisoFAS": "Al darle al botón enviar, se finalizará la denuncia",
            "ajenos": "Datos personales ajenos",
            "sensibles": "Datos Sensibles",
            "anadirusuario": "Añadir usuario a la investigación",
            "falseMensaje": "¿Se van a emprender acciones legales?",
            "exportCase": "Exportar caso",
            "caseType": "Tipo de caso: ",
            "create": "CREADA",
            "analysis": "ANÁLISIS",
            "decision": "DECISIÓN",
            "comunication": "COMUNICACIÓN",
            "finished": "FINALIZADA",
            "deleteTitle": "Borrando archivo",
            "deleteMessage": "¿Está seguro que desea eliminar el archivo?",
            "relevant": "¿Es pertinente?",
            "attachFile": "Archivo adjunto:",
            "seephase": "Análisis",
            "high": "ALTA",
            "medio": "MEDIA",
            "low": "BAJA",
            "priorityComplaint": "Prioridad de la denuncia",
            "activateFiles": "Activar subida de archivos al denunciante",
            "activateMessages": "Activar zona de mensajes al denunciante",
            "mensajeConfirmacion": "¿Está usted seguro de que desea cerrar la fase? No se puede volver atrás",
            "phase3": {
                "title": "CASO ",
                "final": "Decisión final",
                "people": "Decisiones sobre las personas afectadas",
                "compliance": "Decisiones sobre el Sistema de Compliance",
                "organization": "Decisiones sobre la operativa de trabajo de la organización",
                "compliant": "Decisiones sobre otras partes interesadas implicadas en la denuncia",
                "comunication": "Decisiones sobre la comunicación",
                "other": "Otras deciciones",
                "observation": "Observaciones",
                "save": "Guardar",
                "close": "Cerrar",
                "btnpeople": "Personas afectadas",
                "btncompliance": "Sistema de Compliance",
                "btnorganization": "Trabajo de la organización",
                "btncompliant": "Otras partes interesadas implicadas",
                "btncomunication": "Comunicación",
                "btnother": "Otras",
                "message": "El texto de la consulta/denuncia es: ",
                "messagedenu": "El texto de la consulta/denuncia es: ",
                "seephase": "Decisión",
                "return": "Volver",
                "cause": "Causas fase",
                "observationphase2": "Observación de la fase",
                "relevant": "Es pertinente",
                "conclusionPhase2": "Conclusión fase",
                "nodecision": "La denuncia no tiene decisiones",
                "decisionfinal": "Decisión final"
            },
            "phase4": {
                "title": "CASO ",
                "people": "Comunicaciones a las personas afectadas",
                "responsible": "Comunicaciones a los Responsables de las personas afectadas",
                "organization": "Comunicación al resto de personas de la organización",
                "compliant": "Comunicación al denunciante",
                "adminpublic": "Comunicación a la administración pública",
                "other": "Comunicación a otras partes interesadas",
                "observation": "Observaciones",
                "save": "Guardar",
                "close": "Cerrar",
                "btnpeople": "Personas afectadas",
                "btnresponsible": "Responsables",
                "btnorganization": "Resto de la organización",
                "btncompliant": "Denunciante",
                "btnadminpublic": "Administración pública",
                "btnother": "Otras",
                "message": "El texto de la consulta/denuncia es: ",
                "messagedenu": "El texto de la consulta/denuncia es: ",
                "seephase": "Comunicación",
                "return": "Volver",
                "cause": "Causas fase",
                "observationphase2": "Observación de la fase",
                "relevant": "Es pertinente",
                "conclusionPhase2": "Conclusión fase",
                "channel": "Canal / medio de comunicación usado",
                "email": "Correo electrónico",
                "phone": "Teléfono",
                "presencial": "Presencial",
                "otherchannel": "Otros",
                "nocomunication": "La denuncia no tiene comunicaciones",
                "resolucion": "Información de resolución al denunciante",
                "ultentrada": "Último Acceso"
            },
            "messages": {
                "date": "Fecha",
                "text": "Mensaje",
                "title": "Mensajes"
            },
            "filesUsu": {
                "title": "Archivos del denunciante"
            },
            "InviUsu": {
                "title": "Usuarios Añadidos",
                "name": "Nombre",
                "motivo": "Motivo"
            }
        }
    },
    "mwhistleblower": {
        "title": "Denunciante",
        "case": "CASO - ",
        "uploadFile": "Subir archivos",
        "message": "Mensajes",
        "state": "ESTADO ACTUAL DE LA DENUNCIA/CONSULTA: ",
        "finished": "ESTADO ACTUAL DE LA DENUNCIA/CONSULTA: FINALIZADA",
        "comunication": "ESTADO ACTUAL DE LA DENUNCIA/CONSULTA: COMUNICACIÓN",
        "decision": "ESTADO ACTUAL DE LA DENUNCIA/CONSULTA: DECISIÓN",
        "analysis": "ESTADO ACTUAL DE LA DENUNCIA/CONSULTA: EN CURSO",
        "created": "ESTADO ACTUAL DE LA DENUNCIA/CONSULTA: CREADA",
        "messagetext": "Escriba aquí su mensaje...",
        "messageTitle": "Mensaje"
    },
    "phases": {
        "actions": {
            "close": "Cerrar Caso",
            "save": "Guardar",
            "addFile": "Adjuntar nuevo archivo",
            "seeFiles": "Ver historial de archivos",
            "seePDF": "Ver historial PDF"

        },
        "id": "ID",
        "date": "Fecha",
        "type": "Tipo",
        "state": "Estado",
        "lastUser": "Último usuario",
        "commentary": "Comentario"
    },
    "statistics": {
        "title": "FILTRO",
        "date_ini": "Fecha Inicio",
        "date_fin": "Fecha fin",
        "type": "Tipo",
        "relation": "Relación",
        "font": "Fuente",
        "nature": "Naturaleza",
        "priority": "Prioridad",
        "appropriate": "Procedente",
        "msede": "Sede",
        "mdepa": "Departamento",
        "state": "Estado",
        "create": "Creada",
        "analysis": "Análisis",
        "decision": "Decisión",
        "fin": "Finalizada",
        "consulta": "Consulta",
        "anonimo": "Anónimo",
        "nominal": "Nominal",
        "Tcreate": "CREADAS",
        "Tanalysis": "ANÁLISIS",
        "Tdecision": "DECISIÓN",
        "Tcomunicacion": "COMUNICACIÓN",
        "Tfin": "FINALIZADAS",
        "nomfont": "NOMBRE FUENTE",
        "numfont": "TOTAL",
        "total": "Total denuncias",
        "ptotal": "Porcentaje total",
        "filter": "Filtradas",
        "pfilter": "Porcentaje filtradas",
        "resume": "RESUMEN",
        "pstate": "POR ESTADO",
        "pfuente": "POR FUENTE",
        "department": "DEPARTAMENTO",
        "sede": "SEDE",
        "listdenu": "DETALLE",
        "date": "Fecha"
    },
    "search": {
        "title": "Buscador de denuncias/consultas por código",
        "search": "Buscar",
        "code": "Código",
        "whistleblower": "Denunciante"
    },
    "errors": {
        "0": "Datos almacenados correctamente",
        "1": "Se ha producido un error.",
        "1.1": "Revise los datos del formulario",
        "2": "Ya existe el registro",
        "3": "No existen registros",
        "4": "Usuario desbloqueado",
        "8": "Usuario bloqueado",
        "5": "Empresa desbloqueada",
        "9": "Empresa bloqueada",
        "6": "Ha ocurrido un error. Inténtelo de nuevo más tarde.",
        "7": "Debe seleccionar un certificado para poder cerrar el caso",
        "title": "Aviso",
        "fieldOb": "Debe rellenar todos los campos obligatorios (*) y aceptar la política de privacidad",
        "comment": "Los campos con * son obligatorios",
        "acuse": "Acuse de Recibo",
        "errorEmail": "El formato del email no es válido"
    },
    "export": {
        "title": "Exportar",
        "case": "Caso",
        "name": "Nombre: ",
        "surname": "Apellidos: ",
        "email": "Correo electrónico: ",
        "phone": "Teléfono: ",
        "relation": "Relación: ",
        "organization": "Organización: ",
        "nature": "Naturaleza: ",
        "offense": "Delito: ",
        "consultation": "Consulta: ",
        "type": "Tipo: ",
        "department": "Departamento: ",
        "priority": "Prioridad: ",
        "headquarters": "Sede: "
    },
    "configuration": {
        "email": "Correo electrónico",
        "user": "Usuario",
        "password": "Contraseña",
        "server": "Servidor",
        "port": "Puerto",
        "security": "Seguridad",
        "nosec": "Sin seguridad",
        "footer": "Pie legal RGPD",
        "save": "Guardar configuración",
        "repeatPassword": "Repita la contraseña",
        "messageError": "Las contraseñas son diferentes",
        "messageError2": "La contraseña debe tener al menos 8 caractéres y contener una mayúsucula, una minúscula, un número y un símbolo.",
        "change": "Grabar",
        "changePassword": "Cambio de contraseña",
        "newPassword": "Nueva contraseña",
        "pprivacity": "Url Política de Privacidad",
        "advise": "Si es la primera vez que entras, ves al final del formulario y cambia la clave de empresa."
    },
    "legal": {
        "cookieNotice": "Este portal web únicamente utiliza cookies propias con finalidad técnica, no recaba ni cede datos de carácter personal de los usuarios sin su conocimiento. Sin embargo, contiene enlaces a sitios web de terceros con políticas de privacidad ajenas a la nuestra que usted podrá decidir si acepta o no cuando acceda a ellos. Si desea más información, consulte nuestra Política de Cookies pulsando ",
        "cookieNoticeRef": "aquí",
        "pprivacity": "Política de Privacidad",
        "legalWarn": "Aviso Legal",
        "pcookies": "Política de Cookies"
    }
}