import { createRouter, createWebHistory } from 'vue-router'
import Inicio from '../views/Inicio.vue'
import ChangePassword from '../views/CPassword.vue'
import axios from "axios";
import store from '../store/index.js'

const routes = [{
        path: '/:ip/:canal',
        name: 'Inicio',
        meta: {
            isPublic: true
        },
        component: Inicio
    },
    {
        path: '/adminsu/login',
        name: 'Login',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/superuser/Login.vue')
    },
    {
        path: "/error",
        name: "notfound",
        meta: {
            isPublic: true
        },
        component: () =>
            import ('../views/Error.vue')
    },
    {
        path: "/adminsu/mlanguage",
        name: "MLanguage",
        component: () =>
            import ('../views/superuser/mSuperuser/Languages.vue')
    },
    {
        path: "/adminsu/mCountry",
        name: "MCountry",
        component: () =>
            import ('../views/superuser/mSuperuser/Countries.vue')
    },
    {
        path: "/adminsu/mNature",
        name: "MNature",
        component: () =>
            import ('../views/superuser/mSuperuser/Natures.vue')
    },
    {
        path: "/adminsu/mRelation",
        name: "MRelation",
        component: () =>
            import ('../views/superuser/mSuperuser/Relations.vue')
    },
    {
        path: '/adminsu/superuser',
        name: 'Superuser',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/superuser/mSuperuser/Superusers.vue')
    },
    {
        path: '/adminsu/mfont',
        name: 'MFont',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/superuser/mSuperuser/Font.vue')
    },
    {
        path: '/adminsu/ccompany',
        name: 'Company',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/superuser/mSuperuser/Companies.vue')
    },
    {
        path: '/adminsu/statistic',
        name: 'SUSUStatistic',
        component: () =>
            import ('../views/superuser/mSuperuser/Statistics.vue')
    },
    {
        path: '/admin/mdepartment',
        name: 'Department',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/company/mCompany/Departments.vue')
    },
    {
        path: '/admin/mheadquarter',
        name: 'Headquarter',
        component: () =>
            import ('../views/company/mCompany/Headquarters.vue')
    },
    {
        path: '/admin/user',
        name: 'Users',
        component: () =>
            import ('../views/company/mCompany/Users.vue')
    },
    {
        path: '/admin/mylinks',
        name: 'Mydata',
        component: () =>
            import ('../views/company/mCompany/Mylinks.vue')
    },
    {
        path: '/admin/statistic',
        name: 'Statistic',
        component: () =>
            import ('../views/company/mCompany/Statistics.vue')
    },
    {
        path: '/admin/login',
        name: 'LoginEmp',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/company/Login.vue')
    },
    {
        path: '/admin/configuration',
        name: 'CConfiguration',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/company/mCompany/Configuration.vue')
    },
    {
        path: '/:idemp/user/login',
        name: 'LoginUsu',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/user/Login.vue')
    },
    {
        path: '/:idemp/user/restore',
        name: 'RestorePasswordUsu',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/user/RestorePassword.vue')
    },
    {
        path: '/:idemp/user/ocases',
        name: 'OCasesUsu',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/user/mUser/OCases.vue')
    },
    {
        path: '/:idemp/user/chistory',
        name: 'CHistoryUsu',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/user/mUser/CHistory.vue')
    },
    {
        path: '/:idemp/whistleblower/:id',
        name: 'Whistleblower',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/whistleblower/Inicio.vue')
    },
    {
        path: '/:idemp/whistleblower/search',
        name: 'SEARCHWITHID',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ('../views/whistleblower/Search.vue')
    },
    {
        path: '/:idemp/changepassword/:code',
        name: 'CHANGEPASSWORD',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ChangePassword
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async(to, from, next) => {
    const params = to.fullPath.split('/');
    let color = -1;
    if (to.fullPath.includes('denuncia')) {
        next(`/${params[2]}/${params[3]}`);
    } else {
        if (!to.matched.some(record => record.meta.isPublic)) {
            let res = ''
            if (to.path.includes('/adminsu')) {
                res = await axios.post(
                    `${store.state.servidor}api/ident.asp?ac=comprobar`
                );
            } else if (to.path.includes('/admin')) {
                res = await axios.post(
                    `${store.state.servidor}api/ident_empresa.asp?ac=comprobar`
                );
            } else if (to.path.includes('/user')) {
                res = await axios.post(
                    `${store.state.servidor}api/ident_usuario.asp?ac=comprobar`
                );
            }
            if (res && res.data.RESULT === '1') {
                console.log('error')
                if (to.path.includes('/adminsu')) {
                    next('/adminsu/login');
                } else if (to.path.includes('/admin')) {
                    next('/admin/login')
                } else if (to.path.includes('/user')) {
                    if (params.length > 3) {
                        next(`/${params[1]}/user/login`);
                    } else {
                        next();
                    }
                }
            } else {
                if (to.path.includes('/whistleblower')) {
                    const colorRes = await axios.post(
                        `${store.state.servidor}api/op_denuncia.asp?tp=denunciante&ac=vercolor&id=${params[1]}`
                    );
                    color = colorRes.data.COLOR;
                    const logo = `${store.state.servidor}api/ver_logotipo.asp?emp_id=${params[1]}`;
                    store.commit('changeLogo', logo);
                } else if (to.path.includes('/adminsu')) {} else if (to.path.includes('/admin')) {
                    const emp_id = res.data.EMP_ID;
                    color = res.data.EMP_COLOR;
                    store.state.id_emp = emp_id;
                    const logo = `${store.state.servidor}api/ver_logotipo.asp?emp_id=${emp_id}`;
                    store.commit('changeLogo', logo);
                } else if (to.path.includes('/user')) {
                    const usu_rol = res.data.USU_ROL;
                    const id_rol = res.data.USU_ID;
                    store.state.usu_rol = usu_rol;
                    store.state.id_usu = id_rol;
                    const logo = `${store.state.servidor}api/ver_logotipo.asp?emp_id=${params[1]}`;
                    store.commit('changeLogo', logo);
                }
                if (color === 0) {
                    store.commit('changeColorCompanyOrange');
                } else if (color === 1) {
                    store.commit('changeColorCompanyBlue');
                } else if (color === 2) {
                    store.commit('changeColorCompanyGreen');
                } else if (color === 3) {
                    store.commit('changeColorCompanyRed');
                } else if (color === 4) {
                    store.commit('changeColorCompany4');
                } else if (color === 5) {
                    store.commit('changeColorCompany5');
                } else if (color === 6) {
                    store.commit('changeColorCompany6');
                } else if (color === 7) {
                    store.commit('changeColorCompany7');
                } else if (color === 8) {
                    store.commit('changeColorCompany8');
                } else if (color === 9) {
                    store.commit('changeColorCompany9');
                } else if (color === 10) {
                    store.commit('changeColorCompany10');
                } else if (color === 11) {
                    store.commit('changeColorCompany11');
                } else if (color === 12) {
                    store.commit('changeColorCompany12');
                } else if (color === 13) {
                    store.commit('changeColorCompany13');
                } else if (color === 14) {
                    store.commit('changeColorCompany14');
                } else if (color === 15) {
                    store.commit('changeColorCompany15');
                } else if (color === 16) {
                    store.commit('changeColorCompany16');
                }
                next();
            }
        } else {
            next();
        }
        // next()
    }
})

export default router