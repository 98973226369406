{
    "actions": {
        "clean": "Clean",
        "close": "Close",
        "send": "Send",
        "save": "Save",
        "singin": "Sign in",
        "toattach": "Documentation/Evidence",
        "restore": "Restore",
        "return": "Return",
        "confirm": "Accept",
        "cancel": "Cancel",
        "access": "Access",
        "create": "Create",
        "cerrarFase": "Close phase"
    },
    "forms": {
        "title": "Cmpliants Channel",
        "titlef1": "Welcome to our complaints channel, a space dedicated to compliance with law 2/2023 of February 20 in Spain!",
        "titlef2": "On this channel, we are committed to being a trusted vehicle for those who wish to assert their rights and contribute to justice. Law 2/2023 establishes the fundamental regulations that govern our behavior as a society, and our objective is to ensure that they are rigorously complied with.",
        "titlef3": "We focus on giving everyone the opportunity to report any infractions or irregularities they observe in their environment, whether in the public or private sphere. We believe in the importance of maintaining a fair and ethical community, where everyone can live and work in accordance with the principles established by law.",
        "titlef4": "Our team is committed to confidentiality and seriousness in the handling of each complaint received. We work in collaboration with the relevant authorities to ensure that each case is treated with the attention and importance it deserves.",
        "titlef5": "Together, we build a space where transparency and legality are fundamental values. By joining forces, we contribute to the construction of a more just society that respects the laws that govern it.",
        "titlef6": "Thank you for joining us on this journey toward a safer, fairer, and more respectful environment. Your participation is crucial to the success of our mission. Let's report together to build a more compliant and ethical future!",
        "titlef7": "For more information about the channel click ",
        "fraseinicial": "VERY IMPORTANT: If the complaint may affect the Administrator of the company or any of the members of the Ethics Committee, please always use the ANONYMOUS complaint form. Additionally, you can contact us to learn more about our Ethics Committee and the rest of our Compliance System, through the ordinary contact channels with our organization.",
        "subtitle": "Here you can report incidents or ask us questions regarding legal breaches, breaches of our guidelines and procedures, or breaches of any other kind",
        "subtitle2": "This is not an emergency service. In the face of any imminent danger, contact the competent authorities",
        "email": "Email address",
        "user": "User",
        "name": "Name",
        "password": "Password",
        "errorName": "Requirement: Enter name",
        "complaintsChannel": "Complaints Channel",
        "consult": "Consultation",
        "complaint": "Complaint",
        "anonymous": "Anonymous",
        "nominal": "Nominal",
        "finalMesssage": "You can check the status of the same through the url: ",
        "finalMesssage2": "Entering the following code: ",
        "finalMessage3": "A copy can be downloaded,you do ",
        "finalDenuncia": "If you want to track the progress of the complaint, you must save the link and the code that appear on the screen.",
        "finalDenuncia2": "We recommend that you access your report frequently to see if we have contacted you.",
        "typeChannel": "What would you like to do?",
        "typeComplaint": "Type of complaint",
        "answer": "Answer",
        "privacity": "I have read and accept the ",
        "privacity2": "I have read the ",
        "capa1_GEN": "Data Controller: ",
        "capa2_GEN": ". Purpose: Monitoring, management and resolution of the request received through the ethical channel. Exercise of rights and more information on data processing:",
        "capa1_1": "In accordance with data protection regulations, we provide you with the following treatment information:",
        "capa1_2": "Responsible party:  HEALTH TIME, S.L. (EN ADELANTE HT MEDICA).",
        "capa1_3": "Purposes of the treatment: adequately manage our ethical channel, processing the corresponding irregularities notified through it",
        "capa1_4": "Rights that assist you: access, rectification, portability, deletion, limitation and opposition",
        "capa1_5": "More information on the treatment in our privacy policy",
        "capa1_6": "Your consent is necessary for the subsequent processing of this data in the procedure and without which it would not be possible to process your request.",
        "anonimato": "To maintain anonymity, it is recommended to only upload files in pdf format",
        "policy": "Privacy Policy",
        "consultation": {
            "name": "Name",
            "surname": "Surnames",
            "phone": "Phone",
            "email": "Email",
            "ordinario": "Ordinary mail",
            "otromedia": "Other means of communication?",
            "anompo": "Anonymized by ",
            "mediocom": "mean",
            "otros": "Others",
            "organization": "Organization to which it belongs",
            "relationship": "What relationship does it have?",
            "message": "What is your consultation?",
            "nature": "Nature of the consultation",
            "notificacionMensaje": "Receive notifications by ",
            "bloquear": "Is the Channel Manager (Compliance Officer) or the Government Body involved in the reported facts?",
            "bloqueoOrgano": "Yes, the Governing Body (will NOT receive notification of the complaint)",
            "bloqueoOffice": "Yes, the Compliance Officer (will NOT receive notification of the complaint)",
            "bloquearEsp": "Is the Channel Manager  or the Government Body involved in the reported facts?",
            "bloqueoOrganoEsp": "Yes, the Governing Body (will NOT receive notification of the complaint)",
            "bloqueoOfficeEsp": "Yes, the Channel Manager (will NOT receive notification of the complaint)",
            "defajenos": "Your complaint will be anonymized because it includes personal data unrelated to the reported facts. Please, send the complaint again without including these data.",
            "defsensibles": "Your complaint will be anonymized because it includes personal data belonging to the special categories of data. Please, send the complaint again without including these data."

        },
        "comp": {
            "whatComp": "What is your complaint?",
            "involved": "Person(s) involved",
            "involved2": "If you do not know the identity of the person(s) involved write `I do not know`",
            "department": "Affected department",
            "headquarters": "Headquarters affected",
            "otherComplaints": "Complaint description",
            "other": "Detail other complaint",
            "nature": "Nature of the complaint",
            "when": "When did the incident occur?",
            "reiterativo": "Is the incident repetitive?",
            "yes": "Yes",
            "no": "No",
            "bloqmiembro": "Block Member"
        },
        "principalBreadCrumb": "Consultation or Complaint",
        "profile": "View profile",
        "settings": "Settings",
        "contact": "Contact",
        "signout": "Sign out",
        "imgText": "Drag and drop documents or click here"
    },
    "login": {
        "user": "User name",
        "password": "Password",
        "forgot": "Don't remember your password?",
        "title": "Superuser identification",
        "titleEmp": "Company identification",
        "titleUser": "User identification",
        "restore": "Restore",
        "titleRestore": "Restore password",
        "finalMesssage": "Your password has been recovered.",
        "restoreFail": "An error has occurred, please try again.",
        "email": "Email address",
        "error": "An error has occurred, please try again later",
        "errorUsu": "Username or password incorrect",
        "errorBlock": "User is locked out, you can contact an administrator to unlock it",
        "restoreFailEmail": "The email format is invalid"
    },
    "error": {
        "name": "The name is a required field",
        "user": "The user is a required field"
    },
    "menu": {
        "complaintsChannel": "Complaints Channel",
        "mcompany": "CREATE COMPANY",
        "msuperuser": "CREATE SUPERUSER",
        "roleMain": "Role maintenance",
        "countryMain": "COUNTRY MAINTENANCE",
        "offenseMain": "Maintenance of offenses",
        "mainLanguages": "LANGUAGE MAINTENANCE",
        "fontMain": "FONT MAINTENANCE",
        "relation": "RELATION MAINTENANCE",
        "nature": "NATURE MAINTENANCE",
        "headquarters": "HEADQUARTER MAINTENANCE",
        "departments": "DEPARTMENT MAINTENANCE",
        "users": "USER MAINTENANCE",
        "mydata": "MY DATA",
        "statistics": "STATISTICS",
        "whistleblower": "WHISTLEBLOWER",
        "history": "CASE HISTORY",
        "ocases": "OPEN CASES",
        "title": "Hi",
        "mylinks": "MY LINKS",
        "notice": "MY NOTICES",
        "noticemin": "My notices",
        "searcher": "SEARCHER",
        "config": "CONFIGURATION",
        "changep": "PASSWORD CHANGE"
    },
    "maintenance": {
        "actions": {
            "add": "Add",
            "cancel": "Cancelar",
            "confirm": "Accept"
        },
        "roles": {
            "title": "Role maintenance",
            "name": "Name",
            "rol": "Role",
            "deleteTitle": "Deleting role {name}",
            "deleteMessage": "Are you sure you want to delete the role?",
            "deleteConfirmTitle": "The role has been removed",
            "deleteConfirm": "The role has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing role {name}",
            "editMessage": "The role has been edited"
        },
        "languages": {
            "title": "Language maintenance",
            "name": "Name",
            "key": "Code",
            "deleteTitle": "Deleting language {name}",
            "deleteMessage": "Are you sure you want to delete the language?",
            "deleteConfirmTitle": "The language has been removed",
            "deleteConfirm": "The language has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing language {name}",
            "editMessage": "The language has been edited",
            "addTitle": "Adding new language"
        },
        "countries": {
            "title": "Country maintenance",
            "name": "Name",
            "id": "Code",
            "deleteTitle": "Deleting country {name}",
            "deleteMessage": "Are you sure you want to delete the country?",
            "deleteConfirmTitle": "The country has been removed",
            "deleteConfirm": "The country has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing country {name}",
            "editMessage": "The country has been edited",
            "addTitle": "Adding new country"
        },
        "natures": {
            "title": "Nature maintenance",
            "name": "Name",
            "id": "Code",
            "deleteTitle": "Deleting nature {name}",
            "deleteMessage": "Are you sure you want to delete the nature?",
            "deleteConfirmTitle": "The nature has been removed",
            "deleteConfirm": "The nature has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing nature {name}",
            "editMessage": "The nature has been edited",
            "addTitle": "Adding new nature"
        },
        "relations": {
            "title": "Relation maintenance",
            "name": "Name",
            "deleteTitle": "Deleting relation {name}",
            "deleteMessage": "Are you sure you want to delete the relation?",
            "deleteConfirmTitle": "The relation has been removed",
            "deleteConfirm": "The relation has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing relation {name}",
            "editMessage": "The relation has been edited",
            "addTitle": "Adding new relation"
        },
        "superusers": {
            "title": "Super user",
            "name": "Name",
            "id": "Code",
            "email": "Email address",
            "password": "Password",
            "state": "State",
            "deleteTitle": "Deleting super user {name}",
            "deleteMessage": "Are you sure you want to delete the super user?",
            "deleteConfirmTitle": "The super user has been removed",
            "deleteConfirm": "The super user has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing super user {name}",
            "editMessage": "The super user has been edited",
            "addTitle": "Adding new super user",
            "lock": "Unlock"
        },
        "companies": {
            "title": "Companies",
            "name": "Name ",
            "cif": "CIF",
            "email": "Email address",
            "password": "Password",
            "direction": "Direction",
            "population": "Population",
            "cp": "CP",
            "province": "Province",
            "user": "User",
            "logo": "Logo",
            "designType": "Design type",
            "companyType": "Company type",
            "state": "State",
            "single": "Unique",
            "lang": "Language",
            "iGroup": "Is group?",
            "belongs": "Belongs",
            "country": "Country",
            "group": "What group does the company belong to?",
            "isGroup": "Does the company belong to a group?",
            "is-group": "Group?",
            "deleteTitle": "Deleting company {name}",
            "deleteMessage": "Are you sure you want to delete the company?",
            "deleteConfirmTitle": "The company has been removed",
            "deleteConfirm": "The company has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing company {name}",
            "editMessage": "The company has been edited",
            "addTitle": "Adding new company",
            "imgText": "Arrastre y suelte la imagen o pulse aquí",
            "unlock": "Unlock",
            "lock": "Lock",
            "link": "Link channel complaints:",
            "typeRed": "Red type",
            "typeBlue": "Blue type",
            "typeGreen": "Green type",
            "typeOrange": "Orange type"
        },
        "fonts": {
            "title": "Font maintenance",
            "name": "Name",
            "deleteTitle": "Deleting font {name}",
            "deleteMessage": "Are you sure you want to delete the font?",
            "deleteConfirmTitle": "The font has been removed",
            "deleteConfirm": "The font has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing font {name}",
            "editMessage": "The font has been edited",
            "addTitle": "Adding new font"
        }
    },
    "mCompany": {
        "users": {
            "title": "User maintenance",
            "name": "Name",
            "password": "Password",
            "email": "Email",
            "dni": "DNI",
            "user": "User",
            "governingBody": "Governing body",
            "compliance": "Compliance officer",
            "rol": "Select a role",
            "rolTable": "Role",
            "deleteTitle": "Deleting user {name}",
            "deleteMessage": "Are you sure you want to delete the user?",
            "deleteConfirmTitle": "The user has been removed",
            "deleteConfirm": "The user has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing user {name}",
            "editMessage": "The user has been edited",
            "addTitle": "Adding new user",
            "state": "State",
            "signature": "Do you have a digital signature?"
        },
        "departments": {
            "title": "Department maintenance",
            "name": "Name",
            "deleteTitle": "Deleting department {name}",
            "deleteMessage": "Are you sure you want to delete the department?",
            "deleteConfirmTitle": "The department has been removed",
            "deleteConfirm": "The department has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing department {name}",
            "editMessage": "The department has been edited",
            "addTitle": "Adding new department"
        },
        "headquarters": {
            "title": "Headquarter maintenance",
            "name": "Name",
            "deleteTitle": "Deleting headquarter {name}",
            "deleteMessage": "Are you sure you want to delete the headquarter?",
            "deleteConfirmTitle": "The headquarter has been removed",
            "deleteConfirm": "The headquarter has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing headquarter {name}",
            "editMessage": "The headquarter has been edited",
            "addTitle": "Adding new headquarter"
        },
        "mylink": {
            "title": "My links",
            "linkForms": "Link to forms",
            "linkUser": "Links to user login",
            "linkWhistleblower": "Link status of your consultation"
        },
        "statistic": {
            "title": "My statistics"
        }
    },
    "mUser": {
        "mydata": {
            "title": "My data"
        },
        "chistory": {
            "title": "Case history",
            "titleAnalysis": "Case history > Analysis",
            "titleDecision": "Case history > Decision",
            "titleComunication": "Case history > Comunication",
            "date": "Date",
            "type": "Type",
            "user": "Closed by",
            "code": "Code",
            "governingBody": "Governing body",
            "compliance": "Compliance officer",
            "consult": "Consultation",
            "anonymous": "Anonymous",
            "nominal": "Nominal",
            "case": "CASE",
            "seecomunication": "Whistleblower"
        },
        "ocases": {
            "title": "Open cases",
            "time": "Time",
            "intime": "In time",
            "outtime": "Out of time",
            "titleAnalysis": "Open cases > Analysis",
            "titleDecision": "Open cases > Decision",
            "titleComunication": "Open cases > Comunication",
            "date": "Date",
            "type": "Type",
            "nature": "Nature",
            "phase": "Phase",
            "user": "Assigned to",
            "code": "Code",
            "governingBody": "Governing body",
            "compliance": "Compliance officer",
            "consult": "Consultation",
            "anonymous": "Anonymous",
            "nominal": "Nominal",
            "danonymous": "Complaint Anónima",
            "dnominal": "Complaint Nominal",
            "isCrime": "Is there a crime related to the consultation?",
            "crimes": "You enter the associated offenses",
            "investigation": "Investigations",
            "author": "Author",
            "uploadFile": "User uploaded files",
            "files": {
                "name": "Name",
                "date": "Date",
                "download": "Download"
            },
            "authors": {
                "name": "Name",
                "date": "Date"
            },
            "close": "Close consultation",
            "closedenu": "Close complaint",
            "invPhase": {
                "title": "Investigation phase 1",
                "text": "Answer",
                "cause": "Analysis of the causes",
                "observation": "Observations",
                "relevant": "Is it relevant?",
                "conclusionPhase2": "Final conclusion",
                "yes": "Yes",
                "no": "No"
            },
            "falsa": "False Complaint",
            "avisoFAS": "By clicking the send button, the complaint will be finalized",
            "ajenos": "Other personal data",
            "sensibles": "Sensitive Information",
            "anadirusuario": "Add user to inquiry",
            "falseMensaje": "Will legal action be taken?",
            "exportCase": "Export case",
            "caseType": "Case type: ",
            "create": "CREATED",
            "analysis": "ANALYSIS",
            "decision": "DECISION",
            "comunication": "COMUNICATION",
            "finished": "FINISHED",
            "deleteTitle": "Deleting file",
            "deleteMessage": "Are you sure you want to delete the file?",
            "relevant": "Is it relevant?",
            "attachFile": "Attached file:",
            "seephase": "Analysis",
            "high": "HIGH",
            "medio": "MEDIUM",
            "low": "LOW",
            "priorityComplaint": "Complaint priority",
            "activateFiles": "Enable file upload to user",
            "activateMessages": "Activate user message zone",
            "mensajeConfirmacion": "Are you sure you want to close the phase? You can't go back",
            "phase3": {
                "title": "CASE ",
                "final": "Final decision",
                "people": "Decisions about affected people",
                "compliance": "Decisions on the Compliance System",
                "organization": "Decisions on the organization's work operations",
                "compliant": "Decisions on other interested parties involved in the complaint",
                "comunication": "Communication decisions",
                "other": "Other decisions",
                "observation": "Observation",
                "btnpeople": "Affected people",
                "btncompliance": "The Compliance System",
                "btnorganization": "the organization's work",
                "btncompliant": "Other interested parties involved",
                "btncomunication": "Communication",
                "btnother": "Others",
                "message": "The text of the consultation is:",
                "messagedenu": "The text of the complaint is:",
                "seephase": "Decision",
                "return": "Return",
                "cause": "Causes phase 2",
                "observationphase2": "Observation phase 2",
                "relevant": "Is relevant",
                "conclusionPhase2": "Conclusion phase 2",
                "save": "Save",
                "close": "Close",
                "nodecision": "The complaint has no decisions",
                "decisionfinal": "Final decision"
            },
            "phase4": {
                "title": "CASE ",
                "people": "Communications to affected persons",
                "responsible": "Communications to the Managers of the affected persons",
                "organization": "Communication to the rest of the people in the organization",
                "compliant": "Communication to the complainant",
                "adminpublic": "Communication to the public administration",
                "other": "Communication to other interested parties",
                "observation": "Observation",
                "btnpeople": "Affected people",
                "btnresponsible": "Responsible",
                "btnorganization": "Rest of the organization",
                "btncompliant": "Whistleblower",
                "btnadminpublic": "Public administration",
                "btnother": "Others",
                "message": "The text of the consultation is:",
                "messagedenu": "The text of the complaint is:",
                "seephase": "Comunication",
                "return": "Return",
                "cause": "Causes phase 2",
                "observationphase2": "Observation phase 2",
                "relevant": "Is relevant",
                "conclusionPhase2": "Conclusion phase 2",
                "save": "Save",
                "close": "Close",
                "channel": "Channel / communication medium used",
                "presencial": "Presential",
                "email": "Email",
                "phone": "Phone",
                "otherchannel": "Other media",
                "nocomunication": "The complaint has no communications",
                "resolucion": "Resolution information to the complainant",
                "ultentrada": "Last Access"
            },
            "messages": {
                "date": "Date",
                "text": "Message",
                "title": "Messages"
            },
            "filesUsu": {
                "title": "Files with the complainant"
            },
            "InviUsu": {
                "title": "Users Add",
                "name": "Name",
                "motivo": "Reason"
            }
        }
    },
    "mwhistleblower": {
        "title": "Whistleblower",
        "case": "CASE - ",
        "uploadFile": " Upload files",
        "message": "Messages",
        "state": "STATE: ",
        "finished": "CURRENT STATUS OF THE COMPLAINT/CONSULTATION: FINISHED",
        "comunication": "CURRENT STATUS OF THE COMPLAINT/CONSULTATION: COMUNICATION",
        "decision": "CURRENT STATUS OF THE COMPLAINT/CONSULTATION: DECISION",
        "analysis": "CURRENT STATUS OF THE COMPLAINT/CONSULTATION: IN PROGRESS",
        "created": "CURRENT STATUS OF THE COMPLAINT/CONSULTATION: CREATED",
        "messagetext": "Write your message here...",
        "messageTitle": "Message"
    },
    "phases": {
        "actions": {
            "close": "Close Case",
            "save": "Save",
            "addFile": "Attach new file",
            "seeFiles": "View file history",
            "seePDF": "View PDF history"
        },
        "id": "ID",
        "date": "Date",
        "type": "Type",
        "state": "State",
        "lastUser": "Last user",
        "commentary": "Commentary"
    },
    "statistics": {
        "title": "FILTER",
        "date_ini": "Initial date",
        "date_fin": "Final date",
        "type": "Type",
        "relation": "Relation",
        "font": "Source",
        "nature": "Nature",
        "priority": "Priority",
        "appropriate": "Appropriate",
        "msede": "Headquarter",
        "mdepa": "Department",
        "state": "State",
        "create": "Create",
        "analysis": "Analysis",
        "decision": "Decision",
        "fin": "Finished",
        "consulta": "Consultation",
        "anonimo": "Anonymous",
        "nominal": "Nominal",
        "Tcreate": "CREATE",
        "Tanalysis": "ANALYSIS",
        "Tdecision": "DECISION",
        "Tcomunicacion": "COMUNICATION",
        "Tfin": "FINISHED",
        "nomfont": "SOURCE NAME",
        "numfont": "TOTAL",
        "total": "Total complaints",
        "ptotal": "Total percentage",
        "filter": "Filtered",
        "pfilter": "Filtered percentage",
        "resume": "RESUME",
        "pstate": "BY STATE",
        "pfuente": "BY SOURCE",
        "department": "DEPARTMENT",
        "sede": "HEADQUARTER",
        "listdenu": "DETAIL"
    },
    "search": {
        "title": "Search for complaints/consultation by code",
        "search": "Search",
        "code": "Code",
        "whistleblower": "Whistleblower"
    },
    "errors": {
        "0": "Data stored correctly",
        "1": "An error has occurred",
        "1.1": "Review the form data",
        "2": "Record already exists",
        "3": "There are no records",
        "4": "User unlocked",
        "8": "User blocked",
        "5": "Company unlocked",
        "6": "An error has occurred. Try it again later",
        "7": "You must select a certificate in order to close the case",
        "title": "Notice",
        "acuse": "Acknowledgment of receipt",
        "fieldOb": "The required fields must be filled (*) and you accept the Policy Privacy",
        "comment": "The fields with * are required",
        "errorEmail": "The email format is invalid"
    },
    "export": {
        "case": "Case",
        "name": "Name: ",
        "surname": "Surnames: ",
        "email": "Email: ",
        "phone": "Phone: ",
        "relation": "Relation: ",
        "organization": "Organization: ",
        "nature": "Nature: ",
        "offense": "Offense: ",
        "consultation": "Consultation: ",
        "type": "Type: ",
        "department": "Department: ",
        "priority": "Priority: ",
        "headquarters": "Headquarter: "
    },
    "configuration": {
        "email": "Email",
        "user": "User",
        "password": "Password",
        "server": "Server",
        "port": "Port",
        "security": "Security",
        "nosec": "Without security",
        "footer": "GDPR legal footnote",
        "save": "Save configuration",
        "repeatPassword": "Repeat password",
        "messageError": "Passwords are different",
        "messageError2": "The password must be at least 8 characters long and contain an uppercase, lowercase, a number, and a symbol.",
        "change": "Record",
        "changePassword": "Password change",
        "newPassword": "New password",
        "pprivacity": "Url Privacy Policy",
        "advise": "If this is the first time you enter, go to the end of the form and change the company password."
    },
    "legal": {
        "cookieNotice": "This web portal only uses its own cookies for technical purposes, does not collect or transfer personal data of users without their knowledge. However, it contains links to third-party websites with privacy policies outside ours that you can decide if accept or not when you access them. For more information, see our Cookies Policy by clicking ",
        "cookieNoticeRef": "here",
        "pprivacity": "Privacy Policy",
        "legalWarn": "Legal warning",
        "pcookies": "Cookies policy"
    }
}